function formsOnPage() {
  return document.querySelectorAll('form');
}

function elementsToDisable(parent) {
  return parent.querySelectorAll('[data-disable-with]');
}

function newSpinnerElement() {
  const elem = document.createElement('div');
  elem.setAttribute('class', 'spinner-border spinner-border-sm me-1');
  elem.setAttribute('role', 'status');
  elem.innerHTML = '<span class="visually-hidden">Loading...</span>';

  return elem;
}

function overlaySpinnerElement() {
  const elem = document.createElement('div');
  elem.setAttribute('class', 'position-absolute top-50 start-50 translate-middle');
  elem.appendChild(newSpinnerElement());

  return elem;
}

const disabler = {
  input: (element) => {
    if (element.hasAttribute('value')) {
      element.setAttribute('data-enable-with', element.getAttribute('value'));
      element.setAttribute('value', element.getAttribute('data-disable-with'));
    }
    element.disabled = true;
  },
  button: (element) => {
    element.setAttribute('data-enable-with', element.innerHTML);
    const disableWithValue = element.getAttribute('data-disable-with');
    let spinnerElement;
    switch (disableWithValue) {
      // No text means that want to overlay the spinner in the entire button
      // We are hiding everything in the button and positioning overlay vertically centered
      case '':
        element.innerHTML = `<span style="visibility: hidden">${element.innerHTML}</span>`;
        element.style.position = 'relative';
        spinnerElement = overlaySpinnerElement();
        break;
      // If set to true we will just prepend the spinner before current text
      case 'true':
        break;
      // By default we override the button value with the given text
      default:
        element.innerHTML = disableWithValue;
        break;
    }
    spinnerElement ??= newSpinnerElement();
    element.prepend(spinnerElement);
    element.disabled = true;
  },
};

const enabler = {
  input: (element) => {
    if (element.hasAttribute('value') && element.hasAttribute('data-enable-with')) {
      element.setAttribute('value', element.getAttribute('data-enable-with'));
    }
    element.disabled = false;
  },
  button: (element) => {
    if (element.hasAttribute('data-enable-with')) {
      element.innerHTML = element.getAttribute('data-enable-with');
    }
    element.disabled = false;
  },
};

function disableElement(element) {
  const disable = disabler[element.tagName.toLowerCase()] || (() => {});
  disable(element);
}

export function reEnableDisabledElements() {
  const disabled = document.querySelectorAll('[data-disable-with]');
  disabled.forEach(reEnableElement);
}

function reEnableElement(element) {
  const enable = enabler[element.tagName.toLowerCase()] || (() => {});
  enable(element);
}

// We don't want to cache the page if we've got elements that have
// indicated they are not safe to cache. The main issue is with
// hidden elements holding a resource version.
function pageShouldNotBFCache() {
  return document.querySelectorAll('[data-no-bfcache]').length > 0;
}

document.addEventListener('DOMContentLoaded', () => {
  formsOnPage().forEach((form) => {
    form.addEventListener('submit', (event) => {
      // Is this timeout weird? Yes. It is.
      // See: https://github.com/Gusto/gws-flows/pull/1072
      window.setTimeout(() => {
        elementsToDisable(event.target).forEach(disableElement);
      }, 13);
    });
  });
});

// If any element on the page should not allow the
// bfcache to persist it's value, force a page reload.
window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    reEnableDisabledElements();
  }
  if (event.persisted && pageShouldNotBFCache()) {
    window.location.reload();
  }
});
